/* eslint-disable react-hooks/exhaustive-deps */

import { Card, CardContent, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DescriptionIcon from "@material-ui/icons/Description";
import { useEffect, useState } from "react";
import Dropzone from "react-dropzone";

export default function FileUpload(props: {
    onChanged: (files: File[]) => void
}) {
    const [droppedFiles, setDroppedFiles] = useState<File[]>([]);

    useEffect(
        () => props.onChanged && props.onChanged(droppedFiles), 
        [droppedFiles]);

    return <Dropzone 
        onDrop={acceptedFiles => setDroppedFiles([...droppedFiles, ...acceptedFiles])}
    >
        {({getRootProps, getInputProps}) => 
            <div {...getRootProps()}>
                <Card style={{
                    cursor: 'pointer'
                }}>
                    <CardContent style={{
                        display: 'flex',
                        alignContent: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        <input {...getInputProps()} />
                        
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}>
                            <CloudUploadIcon fontSize="large" style={{
                                display: 'block',
                                marginBottom: 4,
                                opacity: 0.75
                            }} />
                            <span style={{
                                maxWidth: 200,
                                fontSize: 12,
                                opacity: 0.5,
                                textAlign: 'center',
                                lineHeight: '150%'
                            }}>
                                Upload files by clicking here, or dragging and dropping them.
                            </span>
                        </div>

                        {droppedFiles.length > 0 && <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginTop: 16
                        }}>
                            {droppedFiles.map((file, index) => 
                                <File key={index} 
                                    input={file}
                                    onDelete={() => {
                                        setDroppedFiles(droppedFiles.filter(x => x !== file));
                                    }} />)}
                        </div>}
                    </CardContent>
                </Card>
            </div>}
  </Dropzone>
}

function File(props: {
    input: File,
    onDelete: () => void
}) {
    return <div 
        onClick={ev => ev.stopPropagation()}
        style={{
            position: 'relative',
            padding: 16,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: 5,
            boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.3)',
            margin: 8,
            cursor: 'default'
        }}
    >
        <IconButton 
            onClick={ev => {
                props.onDelete();
                ev.stopPropagation();
            }}
            size="small" 
            style={{
                position: 'absolute',
                right: 4,
                top: 4
            }}
        >
            <CloseIcon />
        </IconButton>

        <DescriptionIcon fontSize="large" style={{
            display: 'block',
            opacity: 0.5,
            marginBottom: 4
        }} />
        <span style={{
            fontSize: 14,
            opacity: 1,
            maxWidth: 100,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
        }}>
            {props.input.name}
        </span>
        <span style={{
            fontSize: 12,
            opacity: 0.5,
            paddingTop: 4
        }}>
            {getFriendlySizeText(props.input.size)}
        </span>
    </div>
}

function getFriendlySizeText(size: number) {
    const units = [
        "B",
        "kB",
        "MB",
        "GB"
    ];

    let unitOffset = 0;
    while(size > 1024) {
        size = size / 1024;
        unitOffset++;
    }

    return Math.round(size) + " " + units[unitOffset];
}